import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "./newApi";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

import {
  setPendingPayment,
  initPendingPayment,
  clearCurrentPayment,
  setBoletoUrl,
  userPaymentsFetchRequest,
} from "../containers/Payments/actions";

export default function PaymentSocket() {
  const dispatch = useDispatch();
  const { old_id: oldID, id: userID } = useSelector(
    (state) => state.userData.data
  );

  async function fetchUserPayments() {
    try {
      const headers = { Authorization: localStorage.getItem("token") };

      const userSearch = {
        index: "student",
        query: {
          match: { id: userID },
        },
        size: 1,
      };
      const { data: response } = await api.post("/elastic/", userSearch, {
        headers,
      });

      const userPayments = response.results[0].payment_user || [];
      const initPacotes = await processUserPayments(userPayments, headers);

      dispatch(
        initPendingPayment({
          payload: initPacotes.length ? initPacotes : "blank",
        })
      );
    } catch (error) {
      console.error(`Error in fetchUserPayments: ${error}`);
    }
  }

  async function processUserPayments(userPayments, headers) {
    let initPacotes = [];

    for (const payment of userPayments) {
      if (!["paid", "refused", "unpaid", "canceled"].includes(payment.status)) {
        const pacotes = await fetchPaymentPacotes(payment.payment_id, headers);
        initPacotes = initPacotes.concat(pacotes);
      }
    }

    return [...new Set(initPacotes)]; // Remove duplicados
  }

  async function fetchPaymentPacotes(paymentID, headers) {
    const paymentQuery = {
      index: "payments",
      query: {
        bool: {
          must: [{ match: { payment_id: paymentID } }],
        },
      },
      size: 1000,
    };

    const { data: paymentData } = await api.post("/elastic/", paymentQuery, {
      headers,
    });

    const paymentPacotes = paymentData.results[0].pacote || [];
    const matriculas = await fetchMatriculas(paymentPacotes, headers);

    // Adiciona módulos relacionados à matrícula
    paymentPacotes.push(...matriculas);

    return paymentPacotes
      .filter((pacote) => !pacote.matricula)
      .map((pacote) => pacote.id);
  }

  async function fetchMatriculas(paymentPacotes, headers) {
    const matriculas = [];

    paymentPacotes.forEach((pacote) => {
      pacote.aulas.forEach((aula) => {
        if (aula.number === 0) {
          matriculas.push(aula.id);
          pacote.matricula = true;
        }
      });
    });

    const modules = [];
    for (const aulaID of matriculas) {
      const moduleQuery = {
        index: "modulos",
        query: {
          nested: {
            path: "aulas",
            query: {
              bool: {
                must: [{ match: { "aulas.id": aulaID } }],
              },
            },
          },
        },
      };

      const { data: moduleResponse } = await api.post(
        "/elastic/",
        moduleQuery,
        { headers }
      );
      modules.push(moduleResponse.results[0].pacotes_modulo[0]);
    }
    return modules;
  }

  function setupSocket() {
    const headers = { Authorization: localStorage.getItem("token") };
    const socket = new WebSocket(`${SOCKET_URL}/${oldID}/`);

    socket.onmessage = async function (e) {
      const { content } = JSON.parse(e.data);
      await handleSocketMessage(content, headers);
    };

    return () => socket.close();
  }

  async function handleSocketMessage(content, headers) {
    if (content.status !== "processing") {
      const pacotes = await fetchPaymentPacotes(content.payment_id, headers);

      content.pacotes = pacotes.filter((pacote) => !pacote.matricula);

      dispatch(setPendingPayment({ payment: content }));

      if (content.status === "paid") {
        dispatch(userPaymentsFetchRequest());
        if (content.boleto_url) {
          dispatch(setBoletoUrl(""));
        }
      } else if (content.boleto_url) {
        dispatch(setBoletoUrl(content.boleto_url));
      }
    } else {
      dispatch(clearCurrentPayment());
    }
  }

  useEffect(() => {
    if (userID) {
      fetchUserPayments();
    }

    const socketCleanup = setupSocket();
    return () => socketCleanup();
  }, [userID]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchUserPayments();
    }, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, [userID]);

  return <div />;
}
