import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "material-ui/styles";
import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";
import DefaultLoading from "../../components/Loading";
import ResponsiveCourse from "./ResponsiveCourse.js";
import DesktopCourse from "./DesktopCourse.js";
import PaymentModal from "../Payments/Modais/PaymentModal.js";
import PaymentStatusModal from "../Payments/Modais/PaymentStatusModal";
import SnackBar from "../../components/SnackBar";

import { classInfoFetchRequest } from "../Cursos/actions";
import {
  userPaymentsFetchRequest,
  setChooseCardOpen,
  openFinancialResponsible,
  setCreditPayModalOpen,
  setChooseInstallmentsOpen,
  setPaymentStatusModalOpen,
  setLastPayed,
} from "../Payments/actions";
import { openConfirmExit, closeRegister } from "../Checkout/actions";

const styles = {
  root: {
    scrollbarWidth: "15px",
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    width: "100vw",
    height: "fit-content",
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
`;

function useFetchCourseData(
  params,
  profile,
  lastPayed,
  pacotesModulo,
  pacotesCurso
) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { _courseID, _classID, _moduleID } = params;

    if (pacotesModulo && pacotesCurso && lastPayed.length) {
      const isPaidPackage =
        pacotesModulo.some((pacote) => lastPayed.includes(pacote.id)) ||
        pacotesCurso.some((pacote) => lastPayed.includes(pacote.id));

      if (isPaidPackage) {
        dispatch(
          classInfoFetchRequest({
            courseID: parseInt(_courseID),
            moduleID: parseInt(_moduleID),
            classID: parseInt(_classID),
            profile,
          })
        );
        dispatch(setLastPayed([]));
      }
    }
  }, [lastPayed, pacotesModulo, pacotesCurso, dispatch, params, profile]);
}

function useInitializePayments(profile) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile) {
      dispatch(userPaymentsFetchRequest());
      dispatch(setChooseCardOpen(false));
      dispatch(setPaymentStatusModalOpen(false));
      dispatch(setChooseInstallmentsOpen(false));
      dispatch(setCreditPayModalOpen(false));
      dispatch(openConfirmExit(false));
      dispatch(openFinancialResponsible(false));
      dispatch(closeRegister());
    }
  }, [dispatch]);
}

function Curso({ classes, params }) {
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  const {
    loading,
    error,
    responsive,
    debits,
    currentDebit,
    lastPayed,
    openPaymentModal,
    profile,
    pacotesModulo,
    pacotesCurso,
  } = useSelector((state) => ({
    loading: state.courseGroup.loadCourseData.loading,
    error: state.courseGroup.classInfo.error,
    responsive: state.plataform.responsive,
    debits: state.paymentGroup.debits.data.enabled,
    currentDebit: state.paymentGroup.currentDebit,
    lastPayed: state.paymentGroup.lastPayed,
    openPaymentModal: state.paymentGroup.paymentModal.open,
    profile: state.userData.data.id,
    pacotesModulo: state.courseGroup.currentModule.data.pacotes_modulo,
    pacotesCurso: state.courseGroup.currentCourse.data.pacotes_curso,
  }));

  useFetchCourseData(params, profile, lastPayed, pacotesModulo, pacotesCurso);
  useInitializePayments(profile);

  useEffect(() => {
    const content = document.getElementById("content");
    if (content) {
      content.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    const { _courseID, _classID, _moduleID } = params;
    if (_courseID && _moduleID && _classID) {
      dispatch(
        classInfoFetchRequest({
          courseID: parseInt(_courseID),
          moduleID: parseInt(_moduleID),
          classID: parseInt(_classID),
          profile,
        })
      );
    }
  }, [params, dispatch, profile]);

  const handleScrollChange = useCallback(() => {
    const content = document.getElementById("content");
    setScrolled(content.scrollTop > 220);
  }, []);

  const renderContent = () => {
    if (loading) {
      return (
        <LoadingContainer>
          <DefaultLoading sidebar />
        </LoadingContainer>
      );
    }

    if (responsive) {
      return <ResponsiveCourse debits={debits} />;
    }

    return (
      <DesktopCourse
        debits={debits}
        scrolled={scrolled}
        setScrolled={setScrolled}
      />
    );
  };

  const renderPaymentModal = () => {
    if (debits.length > 0) {
      return (
        <PaymentModal
          open={openPaymentModal}
          price={Math.ceil(currentDebit.value)}
          imageUrl={debits[0].teacher}
          moduleNumber={debits[0].modulo.number}
          moduleID={debits[0].modulo.id}
          courseName={debits[0].curso.name}
          courseID={debits[0].curso.id}
          classNumber={debits[0].aula.number}
          className={debits[0].aula.name}
          pacoteID={currentDebit.id}
        />
      );
    }
    return null;
  };

  if (error) {
    return <div>Erro ao carregar aula</div>;
  }

  return (
    <Dialog id="dialog" scroll="paper" open fullScreen>
      <DialogContent
        id="content"
        onScroll={handleScrollChange}
        classes={{ root: classes.root }}
      >
        {renderContent()}
      </DialogContent>
      {renderPaymentModal()}
      <PaymentStatusModal qpq />
      <SnackBar />
    </Dialog>
  );
}

export default withStyles(styles, { index: 1 })(Curso);
