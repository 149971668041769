import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { BsCheckCircle } from "react-icons/bs";

import ClassContent from "./ClassContent";
import TabResponsive from "./TabResponisve";
import { API as api } from "../../services/servicesAPI";
import { removeModuleWatched, updateModulesWatched } from "../Cursos/actions";

import { ClassForum } from "./ClassForum";

const ClassTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabContent = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  height: fit-content;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Tab = styled.div`
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(229, 229, 229);
`;

const CheckConcludedButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  background: #ffffff;
  width: 100%;
  font-family: Inter;
  color: #808080;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  padding: 16px;
  transition: 125ms;

  :before {
    content: " ";
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    height: 16px;
    aspect-ratio: 1;
    margin-right: 8px;
  }

  :hover {
    filter: brightness(0.95);
  }

  :active {
    transition: 0ms;
    filter: brightness(0.96);
  }

  ${({ watched }) =>
    watched &&
    css`
      color: #ffffff;
      background: #37cd9b;
      border: none;

      > svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }

      ::before {
        display: none;
      }
    `}
`;

const CLASS_STUDY_PLAN_TAB = "plano-de-estudos";
const CLASS_TOPICS_TAB = "resumo";
const CLASS_FORUM_TAB = "forum";

function ClassTabsResponsive({
  classDescription,
  materials,
  topics,
  biblioReferences,
  isLocked,
  cursoId,
  moduloId,
  classId,
  forumGroup,
  forumRedirect,
  classSummary,
}) {
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.courseGroup.modules.data);

  const {
    currentCourse: {
      data: { id: curso },
    },
    currentModule: {
      data: { id: modulo },
    },
    currentClass: {
      data: { id: aula },
    },
  } = useSelector((state) => state.courseGroup);

  const userPayments = useSelector(
    (state) => state.paymentGroup.userPayments.data
  );

  const courseID = useSelector(
    (state) => state.courseGroup.currentCourse.data.id
  );

  const [activeTab, setActiveTab] = useState(CLASS_STUDY_PLAN_TAB);

  function wasWatched() {
    const watchedModule = modules.filter((x) => x.id === modulo)[0]?.aulas;

    if (!watchedModule || !Array.isArray(watchedModule)) return;

    const watchedClassroom = watchedModule.filter((y) => y.id === aula)[0];

    if (!watchedClassroom) return;

    return watchedClassroom?.watched;
  }

  function isLocked(moduleID, aulas = []) {
    if (
      userPayments.modulos.find((modulo) => modulo.moduleID === moduleID) !==
        undefined ||
      userPayments.cursos.find((curso) => curso.courseID === courseID) !==
        undefined
    ) {
      return false;
    } else {
      const aula_0 = aulas.find((aula) => aula.number === 0);
      if (aula_0 !== undefined) {
        return (
          userPayments.matriculas.find(
            (matricula) => matricula.aula === aula_0.id
          ) === undefined
        );
      } else {
        return true;
      }
    }
  }

  async function handleCheckConcluded() {
    const watched = wasWatched();

    if (watched) {
      try {
        await api.delete(
          `cursos/cursos/${curso}/aulas-concluidas/${watched.id}`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );

        return dispatch(
          removeModuleWatched({ payload: { modulo, curso, aula } })
        );
      } catch (err) {
        console.error("Failed to uncheck as complete.", err);
      }

      return console.log({ watched });
    }

    try {
      const { data } = await api.post(
        `cursos/cursos/${curso}/aulas-concluidas/`,
        { curso, modulo, aula },
        { headers: { Authorization: localStorage.getItem("token") } }
      );

      dispatch(updateModulesWatched({ payload: [data] }));
    } catch (err) {
      console.error("Failed to mark as complete.", err);
    }
  }

  useEffect(() => {
    async function updateWatchStatus() {
      try {
        const { data } = await api.get(
          `cursos/cursos/${curso}/aulas-concluidas/`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );

        dispatch(updateModulesWatched({ payload: data }));
      } catch (err) {
        console.error(
          "Failed to retrieve information from attended classes.",
          err
        );
      }
    }

    updateWatchStatus();
  }, []);

  return (
    <ClassTabsContainer>
      {!isLocked(modulo.id, modulo.aulas) && (
        <CheckConcludedButton
          watched={wasWatched()}
          onClick={handleCheckConcluded}
        >
          {wasWatched() && <BsCheckCircle />}
          Marcar como concluída
        </CheckConcludedButton>
      )}

      <TabsContainer>
        <Tab onClick={() => setActiveTab(CLASS_STUDY_PLAN_TAB)}>
          <TabResponsive
            id={CLASS_STUDY_PLAN_TAB}
            name={"PLANO DE ESTUDOS"}
            clicked={activeTab}
          />
          {activeTab === CLASS_STUDY_PLAN_TAB && (
            <TabContent>
              <ClassContent
                description={classDescription}
                materials={materials}
                bibliographics={biblioReferences}
              />
            </TabContent>
          )}
        </Tab>

        <Tab onClick={() => setActiveTab(CLASS_TOPICS_TAB)}>
          <TabResponsive
            id={CLASS_TOPICS_TAB}
            name={"TÓPICOS ABORDADOS"}
            clicked={activeTab}
          />
          {activeTab === CLASS_TOPICS_TAB && (
            <TabContent>
              <div
                dangerouslySetInnerHTML={{ __html: classSummary }}
                style={{ color: "rgba(0, 0, 0, 0.75)" }}
              ></div>
              {/* <ClassContent topics={topics} /> */}
            </TabContent>
          )}
        </Tab>

        {forumGroup && (
          <Tab onClick={() => setActiveTab(CLASS_FORUM_TAB)}>
            <TabResponsive
              id={CLASS_FORUM_TAB}
              name={"FÓRUM"}
              clicked={activeTab}
            />
            {activeTab === CLASS_FORUM_TAB && (
              <TabContent>
                <ClassForum
                  cursoId={cursoId}
                  moduloId={moduloId}
                  redirectURL={forumRedirect}
                />
              </TabContent>
            )}
          </Tab>
        )}
      </TabsContainer>
    </ClassTabsContainer>
  );
}

export default ClassTabsResponsive;
