import React, { useState } from "react";
import styled from "styled-components";
import ClassContent from "./ClassContent";
import { ClassForum } from "./ClassForum";

const ClassTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 665px;
`;

const ClassTabsContent = styled.div`
  display: flex;
  width: 665px;
  padding-top: 32px;
`;

const TabsContainer = styled.div`
  display: flex;
  box-shadow: inset 0px -1px 0px 0px rgb(232, 232, 232);
  width: 100%;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  cursor: pointer;
  border: none;
  user-select: none;
  border-bottom: ${(props) =>
    props.active ? "2px solid rgb(252, 121, 84)" : "2px solid transparent"};

  span {
    color: ${(props) =>
      props.active ? "rgb(252, 121, 84)" : "rgba(0, 0, 0, 0.5);"};
  }

  &:hover {
    span {
      color: rgb(252, 121, 84);
    }
  }
`;

const TabName = styled.span`
  height: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-family: "Proxima Nova";
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;

const CLASS_STUDY_PLAN_TAB = "plano-de-estudos";
const CLASS_TOPICS_TAB = "topicos";
const CLASS_FORUM_TAB = "forum";

function ClassTabs({
  classDescription,
  materials,
  topics,
  biblioReferences,
  courseLocked,
  cursoId,
  moduloId,
  classId,
  forumGroup,
  forumRedirect,
  classSummary,
}) {
  const [activeTab, setActiveTab] = useState(CLASS_STUDY_PLAN_TAB);

  return (
    <ClassTabsContainer>
      <TabsContainer>
        <Tab
          onClick={() => setActiveTab(CLASS_STUDY_PLAN_TAB)}
          active={activeTab === CLASS_STUDY_PLAN_TAB}
        >
          <TabName>Plano de Estudos</TabName>
        </Tab>
        <Tab
          onClick={() => setActiveTab(CLASS_TOPICS_TAB)}
          active={activeTab === CLASS_TOPICS_TAB}
        >
          <TabName>Tópicos abordados</TabName>
        </Tab>
        {forumGroup && (
          <Tab
            onClick={() => setActiveTab(CLASS_FORUM_TAB)}
            active={activeTab === CLASS_FORUM_TAB}
          >
            <TabName>Fórum</TabName>
          </Tab>
        )}
      </TabsContainer>

      <ClassTabsContent>
        {activeTab === CLASS_STUDY_PLAN_TAB && (
          <ClassContent
            description={classDescription}
            materials={materials}
            topics={topics}
            bibliographics={biblioReferences}
          />
        )}

        {activeTab === CLASS_TOPICS_TAB && (
          <div
            dangerouslySetInnerHTML={{ __html: classSummary }}
            style={{ color: "rgba(0, 0, 0, 0.75)" }}
          ></div>
        )}

        {activeTab === CLASS_FORUM_TAB && (
          <ClassForum
            cursoId={cursoId}
            moduloId={moduloId}
            redirectURL={forumRedirect}
          />
        )}
      </ClassTabsContent>
    </ClassTabsContainer>
  );
}

export default ClassTabs;
