import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import styled from "styled-components";

import ResponsiveCourseHeader from "../../components/CourseHeader/responsive";
import Video from "./Video";
import CoursePresentation from "./CoursePresentation";
import LoadingModal from "../Payments/Modais/LoadingModal";
import DefaultLoading from "../../components/Loading";
import VideoButton from "./Video/VideoButton";
import ClassDate from "../../components/ClassDate";
import CurrentClassInfo from "../../components/CurrentClassInfo";
import ClassTabsResponsive from "./ClassTabsResponsive";
import PaymentBar from "./PaymentBar";

import { createDebitRequest } from "../Payments/actions";
import {
  redirectToCheckoutAction,
  classSelectorModalAction,
} from "../Cursos/actions";
import { setCartProducts } from "../Checkout/actions";

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 180px;
  height: fit-content;
`;

const ClassLoadingContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  width: fit-content;
  height: fit-content;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px;
`;

const handleIntercomVisibility = (hide) => {
  const intercom = document.querySelector(".intercom-lightweight-app-launcher");
  if (intercom) {
    intercom.style.display = hide ? "none" : "block";
  }
};

function ResponsiveCourse({ size, debits }) {
  const dispatch = useDispatch();

  const {
    loadingClass,
    loadingModules,
    info,
    currentClass,
    currentDebit,
    courseData,
    showPresentation,
    cursoId,
    moduloId,
  } = useSelector((state) => ({
    loadingClass: state.courseGroup.classInfo.loading,
    loadingModules: state.courseGroup.modules.loading,
    info: state.courseGroup.classInfo.data,
    currentClass: state.courseGroup.currentClass.data,
    currentDebit: state.paymentGroup.currentDebit,
    courseData: state.courseGroup.currentCourse.data,
    showPresentation: state.courseGroup.currentCourse.showPresentation,
    cursoId: state.courseGroup.currentCourse.data.id,
    moduloId: state.courseGroup.currentClass.data.id,
  }));

  const perfil = useSelector((state) => state.userData.data.id);
  const liveID = currentClass.webinar_id;

  useEffect(() => {
    if (!debits.length) {
      const timeout = setTimeout(() => handleIntercomVisibility(true), 1000);
      return () => {
        clearTimeout(timeout);
        handleIntercomVisibility(false);
      };
    }
  }, [debits]);

  const debitRequestBody = {
    perfil,
    curso: courseData.id,
    modulo: currentClass.modulo_id,
    aula: currentClass.id,
  };

  const redirectToCheckout = () => {
    dispatch(redirectToCheckoutAction(true));
    if (courseData.pacotes_modulo.length) {
      dispatch(
        setCartProducts({
          items: [{ id: courseData.pacotes_modulo[0].id }],
          price: courseData.value,
        })
      );
    }

    const checkoutUrl = courseData.checkout_url ?? `/checkout`;
    dispatch(push(checkoutUrl));
  };

  const watchLiveClass = () => {
    window.open(`https://zoom.us/j/${liveID}`, "_blank");
    if (!info.showLive && info.qpq) {
      dispatch(
        createDebitRequest({
          perfil,
          curso: courseData.id,
          modulo: currentClass.id,
        })
      );
    }
  };

  const renderPaymentBar = () => {
    if (
      info.showPaymentBar &&
      !loadingDebits &&
      debits.length > 0 &&
      !showPresentation
    ) {
      const { curso, modulo, aula } = debits[0];
      return (
        <PaymentBar
          moduleNumber={modulo.number}
          courseName={curso.name}
          courseYear={curso.ano}
          classNumber={aula.number}
          className={aula.name}
          category={curso.categoria}
          pacoteID={currentDebit.id}
        />
      );
    }

    return null;
  };

  const renderVideoButton = () => {
    const { type } = info;

    if (type === "notEnrolled") {
      return (
        <VideoButton text="Matricule-se aqui" action={redirectToCheckout} />
      );
    }

    if (type === "live") {
      return <VideoButton text="Entrar na sala" action={watchLiveClass} />;
    }

    if (type === "hasToInscribe") {
      return (
        <VideoButton
          text="Inscrever para aula ao vivo"
          action={() => dispatch(createDebitRequest(debitRequestBody))}
        />
      );
    }

    if (type === "available" && !info.showVideo) {
      return (
        <VideoButton
          text="Liberar gravação"
          action={() => dispatch(createDebitRequest(debitRequestBody))}
        />
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <ResponsiveCourseHeader />

      {renderPaymentBar()}

      {loadingClass || loadingModules ? (
        <ClassLoadingContainer>
          <DefaultLoading sidebar={true} />
        </ClassLoadingContainer>
      ) : showPresentation ? (
        <CoursePresentation />
      ) : (
        <MainContainer>
          <Video responsive={true} info={info} debits={debits.length > 0} />
          <MainWrapper>
            <CurrentClassInfo
              onClick={() => dispatch(classSelectorModalAction({ open: true }))}
              currentClassName={currentClass.name}
              moduleNumber={courseData.moduleNumber}
            />

            {info.classTempo && info.type !== "notEnrolled" && (
              <ClassDate
                date={info.availableDate}
                time={info.time}
                classTempo={info.classTempo}
                responsive={true}
              />
            )}

            {renderVideoButton()}

            <ClassTabsResponsive
              isLocked={
                info.showPaymentBar &&
                !loadingDebits &&
                debits.length > 0 &&
                !showPresentation
              }
              classDescription={currentClass.description}
              biblioReferences={currentClass.bio}
              materials={currentClass.materiais}
              topics={currentClass.editais}
              key={currentClass.number}
              size={size}
              cursoId={cursoId}
              moduloId={moduloId}
              forumGroup={courseData.forum_group}
              forumRedirect={courseData.forum_redirect}
              classSummary={currentClass.class_summary}
            />
          </MainWrapper>
        </MainContainer>
      )}
      <LoadingModal responsive={true} />
    </ResponsiveContainer>
  );
}

export default ResponsiveCourse;
