import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { inviteToForumGroup } from "../../services/forum";

const ClassForumContainer = styled.div`
  font-family: "Proxima Nova";
`;
const ClassForumTitle = styled.p`
  font-size: 18px;
`;
const ClassForumList = styled.ol``;
const ClassForumListItem = styled.li`
  padding: 4px 4px;
`;

export const ClassForum = ({ cursoId, moduloId, redirectURL = "" }) => {
  const [forumURL, setForumURL] = useState("");

  const handleForumInvite = useCallback(async () => {
    const inviteWithSuccess = await inviteToForumGroup({ cursoId, moduloId });

    if (inviteWithSuccess) {
      alert("Acesso à discussão concedido");
    }

    window.open(forumURL, "_blank");
  }, [cursoId, moduloId, forumURL]);

  useEffect(() => {
    if (redirectURL.startsWith("/")) {
      setForumURL("https://forum.clippingcacd.com.br" + redirectURL);
    } else {
      setForumURL("https://forum.clippingcacd.com.br/" + redirectURL);
    }
  }, []);

  return (
    <ClassForumContainer>
      <ClassForumTitle>
        👉 Primeiro acesso? Siga as orientações abaixo:
      </ClassForumTitle>
      <ClassForumList>
        <ClassForumListItem>
          Faça login usando seu usuário e senha{" "}
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            href="https://forum.clippingcacd.com.br"
            target="_blank"
          >
            neste link.
          </a>
        </ClassForumListItem>
        <ClassForumListItem>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleForumInvite()}
          >
            Clique aqui para ser convidado para a sala da disciplina.
          </span>
        </ClassForumListItem>
      </ClassForumList>

      <ClassForumTitle>👉 Já faz parte do fórum?</ClassForumTitle>
      <ClassForumList>
        <ClassForumListItem>
          <a href={forumURL} target="_blank">
            Clique aqui para acessar e participar das discussões.
          </a>
        </ClassForumListItem>
      </ClassForumList>
    </ClassForumContainer>
  );
};
