import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { push } from "react-router-redux";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, CircularProgress } from "@material-ui/core";
import Dialog from "material-ui/Dialog";
import { ArrowBack } from "@material-ui/icons";

import {
  checkoutsFetchRequest,
  checkoutFetchRequest,
  checkoutSetUrl,
  checkoutsOpen,
  resetCheckout,
  closeRegister,
  openConfirmExit,
} from "./actions";

import { redirectToCheckoutAction } from "../Cursos/actions";
import {
  setChoosePaymentOpen,
  setBoletoModalOpen,
  setCreditPayModalOpen,
  userPaymentsFetchRequest,
  setPaymentStatusModalOpen,
  setChooseCardOpen,
  setChooseInstallmentsOpen,
  openFinancialResponsible,
} from "../Payments/actions";

import CreditPaymentModal from "../Payments/Modais/CreditPaymentModal";
import PaymentStatusModal from "../Payments/Modais/PaymentStatusModal";
import ChoosePayment from "../Payments/Modais/ChoosePayment";
import ChooseCard from "../Payments/Modais/ChooseCard";
import ChooseInstallments from "../Payments/Modais/ChooseInstallments";
import FinancialResponsible from "../Payments/Modais/FinancialResponsible";

import Package from "./Package";
import CustomPackage from "./CustomPackage";
import FullPackage from "./FullPackage";
import Register from "./Register";
import ConfirmExit from "./ConfirmExit";

import CheckoutSwitch from "./CheckoutSwitch";
import BoletoModal from "../Payments/Modais/BoletoModal";
import LoadingModal from "../Payments/Modais/LoadingModal";

const Container = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: ${(props) => (props.loading ? "center" : "flex-start")};
  align-items: ${(props) => (props.loading ? "center" : "flex-start")};
  height: fit-content;
  width: 100%;
  font-family: "Proxima Nova";
  padding-bottom: 30px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px;
  width: 100%;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 24px !important;
  @media (max-width: 700px) {
    z-index: 10;
    margin-left: 0px !important;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Packages = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: baseline;
  padding-top: 64px;
  width: 100%;
  height: 100%;
`;

const BackIcon = styled(ArrowBack)`
  width: 24px !important;
  height: 24px !important;
`;

const CustomCircularProgress = styled(CircularProgress)`
  & > svg {
    color: rgb(252, 121, 84);
  }
`;

const resetPaymentState = (dispatch) => {
  dispatch(userPaymentsFetchRequest());
  dispatch(setChoosePaymentOpen(false));
  dispatch(setChooseCardOpen(false));
  dispatch(setPaymentStatusModalOpen(false));
  dispatch(setChooseInstallmentsOpen(false));
  dispatch(setCreditPayModalOpen(false));
  dispatch(setBoletoModalOpen(false));
  dispatch(openConfirmExit(false));
  dispatch(openFinancialResponsible(false));
  dispatch(closeRegister());
};

const renderPackages = (packages) => (
  <Packages>
    {packages.personalizado?.length > 0 &&
      packages.personalizado.map(
        (pack, index) =>
          pack.show && (
            <CustomPackage key={index} index={index} type="personalizado" />
          )
      )}
    {packages.matriculas?.show && <Package type="matriculas" />}
    {packages.matriculas_aulas?.show && (
      <Package middle={true} type="matriculas_aulas" />
    )}
    {packages.pacote_completo?.show && <FullPackage />}
  </Packages>
);

function Checkout({ params, classes }) {
  const dispatch = useDispatch();

  const {
    loading,
    url,
    packages,
    openCreditPayment,
    profileId,
    redirectToCheckout,
    courseID,
    moduleID,
    classID,
  } = useSelector((state) => ({
    loading: state.checkout.currentCheckout.loading,
    url: state.checkout.currentCheckout.url,
    packages: state.checkout.currentCheckout.data,
    openCreditPayment: state.paymentGroup.creditPaymentModal.open,
    profileId: state.userData.data.id,
    redirectToCheckout: state.courseGroup.redirectToCheckout,
    courseID: state.courseGroup.currentCourse.data.id,
    moduleID: state.courseGroup.currentModule.data.id,
    classID: state.courseGroup.currentClass.data.id,
  }));

  const handleIntercomVisibility = () => {
    const intercom = document.querySelector(
      ".intercom-lightweight-app-launcher"
    );
    if (intercom) intercom.style.display = "none";
    return () => {
      if (intercom) intercom.style.display = "block";
    };
  };

  useEffect(() => {
    const cleanupIntercom = handleIntercomVisibility();
    return cleanupIntercom;
  }, []);

  useEffect(() => {
    if (profileId) resetPaymentState(dispatch);
  }, [profileId, dispatch]);

  useEffect(() => {
    if (params?.url) {
      dispatch(checkoutSetUrl({ url: params.url }));
      dispatch(checkoutFetchRequest({ url: params.url }));
    } else {
      returnToCheckoutSelect();
    }
  }, [params, dispatch]);

  const returnToCheckoutSelect = () => {
    dispatch(checkoutSetUrl({ url: "" }));
    dispatch(resetCheckout());
    dispatch(checkoutsOpen());
    dispatch(push("/checkout"));
  };

  const returnToCourses = () => {
    dispatch(resetCheckout());
    dispatch(redirectToCheckoutAction(false));
    if (courseID && moduleID && classID) {
      dispatch(push(`/cursos/${courseID}/modulo/${moduleID}/aula/${classID}`));
    } else {
      dispatch(push("/checkout"));
    }
  };

  return (
    <Dialog open={Boolean(url)} id="dialog" scroll={"paper"} fullScreen>
      {!loading ? (
        <div>
          <Container loading={0}>
            <Header>
              <CloseButton
                onClick={() =>
                  redirectToCheckout
                    ? returnToCourses()
                    : returnToCheckoutSelect()
                }
              >
                <BackIcon />
              </CloseButton>
              <CheckoutSwitch />
            </Header>
            <Content>{renderPackages(packages)}</Content>
          </Container>
          <CreditPaymentModal open={openCreditPayment} qpq={false} />
          <Register />
          <ConfirmExit />
          <BoletoModal />
          <ChoosePayment />
          <FinancialResponsible />
          <ChooseCard qpq={false} />
          <ChooseInstallments />
          <LoadingModal />
          <PaymentStatusModal />
        </div>
      ) : (
        <Container loading={1}>
          <CustomCircularProgress />
        </Container>
      )}
    </Dialog>
  );
}

export default Checkout;
