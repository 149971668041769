import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ErrorOutline } from "@material-ui/icons";
import { getText } from "../../utils/textHandle";
import ClassTabsNoContent from "./ClassTabsNoContent";

const ClassContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const SectionTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const LinkText = styled.a`
  margin-top: 8px;
  color: rgb(25, 115, 232);
  font-family: "Proxima Nova";
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
`;

const DisabledText = styled.span`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 15px;
  line-height: 20px;
`;

const DisabledAlertBox = styled.div`
  width: fit-content;
  background: rgb(255, 236, 194);
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const DisabledAlertText = styled.span`
  font-family: "Proxima Nova";
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(184, 130, 10);
  margin-left: 10px;
`;

const ContentText = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  line-height: 20px;
`;

function ClassContent({
  description = "",
  materials = [],
  topics = [],
  bibliographics = "",
}) {
  const isEnrolled = useSelector(
    (state) => state.courseGroup.classInfo.data.enrolled
  );

  const linkRef = useRef(null);

  useEffect(() => {
    if (linkRef.current) {
      const links = linkRef.current.getElementsByTagName("a");
      for (let i = 0; i < links.length; i++) {
        links[i].setAttribute("target", "_blank");
      }
    }
  }, []);

  if (!description && !materials.length && !topics.length && !bibliographics) {
    return (
      <ClassContentContainer>
        <ClassTabsNoContent />
      </ClassContentContainer>
    );
  }

  return (
    <ClassContentContainer ref={linkRef}>
      {description && (
        <ContentText
          dangerouslySetInnerHTML={{ __html: getText(description) }}
        />
      )}

      <Section
        title="Materiais"
        content={materials}
        isEnrolled={isEnrolled}
        renderContent={(material) =>
          isEnrolled ? (
            <LinkText href={material.url}>{material.name}</LinkText>
          ) : (
            <DisabledText>{material.name}</DisabledText>
          )
        }
        disabledAlert={
          !isEnrolled && (
            <DisabledAlertBox>
              <ErrorOutline
                style={{
                  width: "18px",
                  height: "18px",
                  color: "rgb(184, 130, 10)",
                }}
              />
              <DisabledAlertText>
                Matricule-se para ter acesso aos materiais.
              </DisabledAlertText>
            </DisabledAlertBox>
          )
        }
      />

      <Section
        title="Tópicos do edital"
        content={topics}
        renderContent={(topic) => (
          <ContentText>{`${topic.orderName} - ${topic.nome}`}</ContentText>
        )}
      />

      {bibliographics && (
        <SectionContainer>
          <SectionTitle>Referências Bibliográficas</SectionTitle>
          <ContentText
            dangerouslySetInnerHTML={{ __html: getText(bibliographics) }}
          />
        </SectionContainer>
      )}
    </ClassContentContainer>
  );
}

const Section = ({
  title,
  content,
  renderContent,
  disabledAlert,
}) => {
  if (!content.length) return null;

  return (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      {content.map((item, index) => (
        <React.Fragment key={index}>{renderContent(item)}</React.Fragment>
      ))}
      {disabledAlert}
    </SectionContainer>
  );
};

export default ClassContent;
