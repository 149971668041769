import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import {
  categoryFetchRequest,
  courseFetchRequest,
  courseModalAction,
  setExpandedCourseCard,
} from "./actions";

import {
  userPaymentsFetchRequest,
  setCreditPayModalOpen,
  setPaymentStatusModalOpen,
} from "../Payments/actions";

import CollapsableCard from "./CollapsableCard";
import Banner from "./Banner";
import LoadingModal from "./LoadingModal";
import SnackBar from "../../components/SnackBar";

import DefaultLoading from "../../components/Loading";

const Container = styled.div`
  transition: max-height 0.15s ease-out;
  padding-top: 50px;
`;

function Cursos() {
  const dispatch = useDispatch();
  const profileId = useSelector((state) => state.userData.data.id);

  useEffect(() => {
    if (profileId) {
      window.scrollTo(0, 0);
      dispatch(setCreditPayModalOpen(false));
      dispatch(setPaymentStatusModalOpen(false));
      dispatch(categoryFetchRequest());
      dispatch(courseFetchRequest());
      dispatch(userPaymentsFetchRequest());
    }
  }, [dispatch, profileId]);

  useEffect(() => {
    return () => dispatch(setExpandedCourseCard({ payload: "" }));
  }, []);

  const {
    categoriesLoading,
    coursesLoading,
    courseDataLoading,
    categoriesError,
    coursesError,
    moduleError,
    categories: rawCategories,
    courses,
    expanded,
  } = useSelector((state) => ({
    categoriesLoading: state.courseGroup.categories.loading,
    coursesLoading: state.courseGroup.courses.loading,
    courseDataLoading: state.courseGroup.loadCourseData.loading,
    categoriesError: state.courseGroup.categories.error,
    coursesError: state.courseGroup.courses.error,
    moduleError: state.courseGroup.modules.error,
    categories: Object.values(state.courseGroup.categories.data),
    courses: state.courseGroup.courses.data,
    expanded: state.courseGroup.expandedCard,
  }));

  const categories = rawCategories;

  // Show Loading
  if (categoriesLoading || coursesLoading) {
    return <DefaultLoading />;
  }

  if (courseDataLoading) {
    return <LoadingModal />;
  }

  // Show Error
  if (categoriesError || coursesError) {
    return <div>Falha ao carregar lista de cursos</div>;
  }

  const years = [...new Set(courses.map((course) => course.ano))];
  const coursesByYear = {};

  years.map((year) => {
    coursesByYear[year] = [...courses.filter((items) => items.ano === year)];
  });
  const coursesToRender = [];

  Object.keys(coursesByYear)
    .sort((courseA, courseB) => courseB - courseA)
    .map((key) => {
      categories.map((category) => {
        const filteredCourses = coursesByYear[key].filter(
          (items) => category.id === items.categoria.id && items.visible
        );

        if (filteredCourses.length) {
          coursesToRender.push(
            <CollapsableCard
              key={`${category.nome}-${key}`}
              title={category.nome}
              contest="cacd"
              year={key}
              courses={filteredCourses}
              expanded={expanded === `${category.nome}-${key}`}
            />
          );
        }
      });
    });

  return (
    <div>
      <Container>
        {coursesToRender.map((item, index) =>
          index === 0 ? (
            <div key={index}>
              <Banner
                title={"CURSOS COM 21% DE DESCONTO"}
                mobileTitle={"CURSOS COM 21% DE DESCONTO"}
                label={"Curso Intensivo de 1ª Fase CACD ⚠️ Desconto limitado"}
                link={"/checkout"}
              />
              {item}
            </div>
          ) : (
            item
          )
        )}
        {moduleError && "Falha ao carregar módulos"}
        <SnackBar />
      </Container>
    </div>
  );
}

export default Cursos;
