import { API } from "../servicesAPI";

export async function inviteToForumGroup({ cursoId, moduloId }) {
  try {
    await API.post(
      "cursos/discourse/add-to-group/",
      {
        curso_id: cursoId,
        modulo_id: moduloId,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}
